import {Component, Inject, OnInit} from '@angular/core';
import {animateMobileTrigger, listStateTrigger} from '../shared/animations';
import {IRoute} from './route';
import {RouteService} from './routeService';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser} from '@angular/common';
import {AuthService} from '../auth/auth.service';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../app.reducer';
import {IUser} from '../user/user.model';
import {UserMessageService} from '../user/user-message/user-message.service';
import {AppointmentService} from '../user/appointment.service';

@Component ({
  selector: 'qss-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    animateMobileTrigger,
    listStateTrigger
  ]
})

export class HeaderComponent implements OnInit {

  isNavMenu = false;
  isAccountMenu = false;
  routeItems: IRoute[];
  isBrowser: boolean;
  // isAuth = false;
  isAuth$: Observable<boolean>;
  // authSubscription : Subscription;
  user: Observable<IUser>;

  // TODO I am trying to think of how to get routerLink working for profile
  routeActivated = false;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    public authService: AuthService,
    private _surgeryMessageService: UserMessageService,
    private _appointmentService: AppointmentService,
    // TODO Fix ASAP
    // private _medicationService: MedicationService,
    private store: Store<fromRoot.State>) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {

    // We now use reducer
    this.isAuth$ = this.store.select(fromRoot.getIsAuth);

    // this.authSubscription = this.authService.authChange.subscribe( authStatus => {
    // this.isAuth = authStatus;
    // });

    // this.routeService.loadRoutes()
    //   .subscribe(
    //     (routes: IRoute[]) => {
    //
    //
    //       //TODO
    //       // I want to be fancy by subscribing to my routes.
    //       // Currently I am able to filter and only show the routes that are active.
    //       // But struggling to conditionally apply the active state to show or hide Log In
    //
    //       // this.routeItems = routes;
    //
    //       const filterRoutes = (routes: IRoute[]) =>
    //         routes.filter(route => route.active == true);
    //       this.routeItems = filterRoutes(routes)
    //     }
    //   );
  }

  menuOn() {
    this.isNavMenu = !this.isNavMenu;
  }

  menuOff() {
    this.isNavMenu = false;
  }

  toggleAccountMenu() {
    this.isAccountMenu = !this.isAccountMenu;
  }

  onLogout() {
    this._surgeryMessageService.cancelSubscriptions();
    this._appointmentService.cancelSubscriptions();
    // this._medicationService.cancelSubscriptions();
    this.authService.logout();
  }

  // We now use reducer
  // ngOnDestroy(): void {
  //   if(this.authSubscription) {
  //     this.authSubscription.unsubscribe()
  //   }
  // }
}

