import { Injectable } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {IUser} from './user.model';
import {Subject, Subscription} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../app.reducer';
import {default as _rollupMoment} from 'moment';
import * as _moment from 'moment';
import * as UI from '../shared/ui.actions';
import {map} from 'rxjs/operators';
const moment = _rollupMoment || _moment;

@Injectable()
export class UserService {
  userData: IUser;
  user: IUser;
  private fbSubs: Subscription[] = [];
  createMessageSubject = new Subject<boolean>();
  hasScriptSubject = new Subject<boolean>();
  userSubject = new Subject<IUser[]>();
  singleUserSubject = new Subject<IUser>();
  private allUsers: IUser[] = [];


  constructor(private afAuth: AngularFireAuth,
              private auth: AuthService,
              private store: Store<{ui: fromRoot.State}>,
              private afs: AngularFirestore) {
    this.auth.user$.subscribe(user => {
      this.userData = user;
    });
  }

  getUsers() {
    this.store.dispatch(new UI.StartLoading());
    this.fbSubs.push(
    this.afs
      .collection<IUser>('users', ref => ref.orderBy('surname', 'asc'))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(action => {
            const data = action.payload.doc.data() as IUser;
            return {
              userId: action.payload.doc.id,
              name: data.name,
              surname: data.surname,
              birthday: data.birthday,
              phone: data.phone,
              idDocument: data.idDocument,
              medicalNumber: data.medicalNumber,
              adminNote: data.adminNote,
              doctor: data.doctor,
              email: data.email,
              roles: data.roles,
            };
          });
        })
      )
      .subscribe((users: IUser[]) => {
        this.store.dispatch(new UI.StopLoading());
        this.allUsers = users;
        this.userSubject.next([...this.allUsers]);
      }));
  }


  getUserDetail(userId: string) {
    this.store.dispatch(new UI.StartLoading());
    this.fbSubs.push(
    this.afs
      .doc<IUser>(`users/${userId}`)
      .snapshotChanges()
      .pipe(
        map(action => {
            const data = action.payload.data() as IUser;
            return {
              userId: action.payload.id,
              name: data.name,
              surname: data.surname,
              birthday: data.birthday,
              phone: data.phone,
              idDocument: data.idDocument,
              medicalNumber: data.medicalNumber,
              adminNote: data.adminNote,
              doctor: data.doctor,
              email: data.email,
              roles: data.roles,
            };
        })
      )
      .subscribe((user: IUser) => {
        this.store.dispatch(new UI.StopLoading());
        this.user = user;
        this.singleUserSubject.next(this.user);
      })
    );
  }

  checkCompleteUserData(): boolean {
    if (this.userData) {
      return !!(this.userData.phone || this.userData.phone === '')
        && !!(this.userData.birthday)
        && !!(this.userData.idDocument)
        && !!(this.userData.doctor);
    }
  }

  checkScript(): boolean {
    if (this.userData) {
      this.afs.collection('users').doc(`${this.userData.userId}`)
        .collection('prescription').get().toPromise()
        .then(sub => {
          if (sub.docs.length > 0) {
            this.hasScriptSubject.next(true);
          }
        });
      return;
    }
  }

  updatePatientNote(userId, adminNote) {
    const path = `users/${userId}`;

    return this.afs.doc(path).update(adminNote)
      .catch()
      .then(() => {
        this.createMessageSubject.next(true);
      });
  }

  sendUpdateUserData(userData) {
    this.afAuth.authState.subscribe(user => {
      this.updateUserData(user, userData);
    });
  }

  updateUserData(user, userData) {
    const path = `users/${user.uid}`;
    const data = {
      userId: user.uid,
      phone: userData.phone,
      birthday: userData.birthday !== null ? moment(userData.birthday).toDate() : null,
      idDocument: userData.idDocument,
      medicalNumber: userData.medicalNumber,
      adminNote: userData.adminNote ? userData.adminNote : null,
      doctor: userData.doctor
    };
    return this.afs.doc(path).update(data)
      .catch()
      .then(() => {
        this.createMessageSubject.next(true);
      });
  }
}
