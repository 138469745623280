<div class="auth-wrapper">
  <div class="login-signup" [ngClass]="{'valid-form' : signupForm.valid, 'error-form' : hasAuthError}">
    <h2>
      Sign Up
    </h2>
    <div class="form-error-message" *ngIf="hasAuthError">
      <h4>
        {{errorMessage}}
      </h4>
      <!--<p>-->
        <!--Did you forget your password?-->
      <!--</p>-->
    </div>
    <form class="form"
          [formGroup]="signupForm">
      <mat-form-field appearance="outline">
        <mat-label>First Name</mat-label>
        <input matInput
               formControlName="name">
        <mat-error>Name Required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Surname</mat-label>
        <input type="text"
               matInput
               formControlName="surname">
        <mat-error>Surname Required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Email Address</mat-label>
        <input type="email"
               matInput
               formControlName="email">
        <mat-error *ngFor="let validation of validationMessages.email">
          <mat-error class="error-message" *ngIf="signupForm.get('email').hasError(validation.type) && (signupForm.get('email').dirty || signupForm.get('email').touched)">{{validation.message}}</mat-error>
        </mat-error>
      </mat-form-field>

      <div formGroupName="matchingPasswords" class="signup-password">
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input type="password"
                 matInput
                 formControlName="password" required>
          <mat-error *ngFor="let validation of validationMessages.password">
            <mat-error class="error-message"
                       *ngIf="signupForm.get('matchingPasswords').get('password').hasError(validation.type) && (signupForm.get('matchingPasswords').get('password').dirty ||
                        signupForm.get('matchingPasswords').get('password').touched)">
              {{validation.message}}
            </mat-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Confirm Password</mat-label>
          <input type="password"
                 matInput
                 formControlName="confirmPassword" [errorStateMatcher]="parentErrorStateMatcher" required>
          <mat-error *ngFor="let validation of validationMessages.confirm_password">
            <mat-error class="error-message"
                       *ngIf="(signupForm.get('matchingPasswords').get('confirmPassword').hasError(validation.type)||
                       signupForm.get('matchingPasswords').hasError(validation.type)) && (signupForm.get('matchingPasswords').get('confirmPassword').dirty ||
                       signupForm.get('matchingPasswords').get('confirmPassword').touched)">
              {{validation.message}}
            </mat-error>
          </mat-error>
        </mat-form-field>
      </div>

      <button type="submit"
              class="qss-button--green"
              (click)="onSubmit()"
              *ngIf="!(isLoading$ | async)"
              [disabled]="signupForm.invalid">
        Create Account
      </button>
      <mat-spinner *ngIf="(isLoading$ | async)"></mat-spinner>
    </form>
    <div class="signup-login-link">
      <div class="signup-login-link__action">
        <h4>
          Already have an account?
        </h4>
        <a class="qss-button--text-only" routerLink="/login">
          Log In
        </a>
      </div>
    </div>
  </div>
</div>
