import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {fadeAnimation} from './shared/route-animations';
import {RegisterIconsService} from './shared/icons/register-icons.service';
import {AuthService} from './auth/auth.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {isPlatformBrowser} from '@angular/common';
import {ConfirmationModalComponent} from './shared/confirmation-modal/confirmation-modal.component';
import {filter} from 'rxjs/operators';
declare let gtag: Function;

@Component({
  selector: 'qss-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [fadeAnimation]
})

export class AppComponent implements OnInit {

  // showSpinner = true;

  // Old way for navigating to to top after routing

  // constructor(@Inject(WINDOW) private window: Window, private router: Router) {}
  //
  // ngOnInit() {
  // this.router.events.pipe(
  //   filter(event => event instanceof NavigationEnd)
  // )
  //   .subscribe(() => window.scrollTo(0, 0));
  // }

  tags = {
    title: 'Queen Street Surgery - Knysna General Practitioners',
    description: 'Queen Street Surgery is the central provider of family care in Knysna, South Africa. ' +
      'It provides the full spectrum of medical care by doctors.',
    image: 'https://queenstreetsurgery.co.za/assets/img/logo-overlay.jpg',
    slug: '',
  };

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private registerIconsService: RegisterIconsService) {
    if (isPlatformBrowser(this.platformId)) {
      this.authService.initAuthListener();
    }
  }


  ngOnInit(): void {

    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.panelClass = 'notice-class';
    // dialogConfig.data = {
    //   headline: 'Coronavirus Notice',
    //   headlineIcon: 'clipboard--s',
    //   body: ' We are now asking the patients to stop in front of the sign at our front door. ' +
    //     'Should you display any signs of high fever, flu-like symptoms, and sore throat, we will direct you to an area outside where ' +
    //     'the doctors park their cars. The doctors will then assist you outside.',
    //   actionLabel: 'Okay',
    // };
    // const dlg = this.matDialog.open(ConfirmationModalComponent, dialogConfig);

    const navEndEvent$ = this.router.events.pipe(filter(e => e instanceof NavigationEnd));

    // // Set a title
    // this._meta.setTitle(this.tags.title);
    // this._meta.setTag('description', this.tags.description);
    //
    // // Set meta tags
    // this._meta.setTag('twitter:card', 'summary');
    // this._meta.setTag('twitter:site', '@QueenStSurgery');
    // this._meta.setTag('twitter:title', this.tags.title);
    // this._meta.setTag('twitter:description', this.tags.description);
    // this._meta.setTag('twitter:image', this.tags.image);
    //
    // this._meta.setTag('og:type', 'website');
    // this._meta.setTag('og:site_name', this.tags.title);
    // this._meta.setTag('og:title', this.tags.title);
    // this._meta.setTag('og:description', this.tags.description);
    // this._meta.setTag('og:image:secure_url', this.tags.image);
    // this._meta.setTag('og:url', `https://queenstreetsurgery.co.za`);

  }

  // prepareRoute(outlet: RouterOutlet) {
  //   // The below is to be able to pass in dynamic data to allow different route animations.
  //   // If there is only one animation, then none of this is needed and you could pass the single string into the html itself
  //   return outlet && outlet.activatedRouteData && outlet.activatedRouteData['fadeRoute'];
  //   // In the route config, use the Data param and define what component has what animation. This eg it is fadeRoute
  // }
}
