import {formatDate} from '@angular/common';
import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {firestore} from 'firebase/app';
import Timestamp = firestore.Timestamp;

@Pipe({
  name: 'fullDate',
  pure: false
})
export class FullDatePipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private locale: string) {
  }

  transform(timestamp: any, format?: string): string {
    if (!timestamp || !timestamp.toDate) {
      return;
    }
    return formatDate(timestamp.toDate(), format || 'medium', this.locale);
  }
}
