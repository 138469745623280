import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import {IUser} from '../../user/user.model';
import {MatInput} from '@angular/material/input';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', '../signup/signup.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('login', { static: true }) loginInput: MatInput;
  loginForm: FormGroup;
  authSubscription: Subscription;
  hasAuthError = false;
  errorMessage: string;
  resetPassword = false;
  isLoading$: Observable<boolean>;
  isAuthing$: Observable<boolean>;
  user: IUser;
  resetPasswordForm: FormGroup;
  resetMessage;
  messageTrigger = false;
  // private loadingSubscription : Subscription;


  validationMessages = {
    'email': [
      { type: 'required', message: 'Email is required' },
      { type: 'pattern', message: 'Enter a valid email' }
    ],
    'password': [
      { type: 'required', message: 'Password is required' },
      { type: 'minlength', message: 'Password must be at least 5 characters long' },
      { type: 'pattern', message: 'Your password must contain at least one uppercase, one lowercase, and one number' }
    ]
  };

  constructor(private authService: AuthService,
              private store: Store<{ui: fromRoot.State}>) {
  }

  ngOnInit() {


    // We refactored fromApp to fromRoot
    // import * as fromRoot from '../../app.reducer';

    // this.isLoading$ = this.store.map(state => state.ui.isLoading);
    // We no longer have to drill into this object, we can now just use the select function

    this.isLoading$ = this.store.select(fromRoot.getIsLoading);
    this.isAuthing$ = this.store.select(fromRoot.getIsAuth);

    // We adjusted the isLoading from a false boolean to a isLoading$ Observable
    // Now we can comment out the below as well and the ngOnDestroy-----
    // So instead of subscribing to our uiService loadingStateChange and assigning it to boolean property,
    // We made isLoading an Observable and tying it to the isLoading store state. Since it is an Observable, we can make it async

    // this.loadingSubscription = this.uiService.loadingStateChanged.subscribe(isLoading => {
    //   this.isLoading = isLoading;
    // });

    this.loginForm = new FormGroup({
      email: new FormControl('', {
        validators: [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]
      }),
      password: new FormControl('', {
        validators: [Validators.required]
      })
    });
    // TODO now that there is a ngIf you get a undefined error. Come back to below. Google autoFocus when component ready
    // setTimeout( () => {
    //   this.loginInput.focus();
    // }, 100);

  }

  onSubmit() {
    this.authService.login({
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
    });

    this.authSubscription = this.authService.authErrorMessage.subscribe( authMessage => {
      this.errorMessage = authMessage;
    });

    this.authSubscription = this.authService.authError.subscribe( authStatus => {
      this.hasAuthError = authStatus;
      if (this.hasAuthError) {
        this.loginForm.get('password').reset();
      }
    });
  }

  reset() {
    this.resetPassword = true;
    this.resetPasswordForm = new FormGroup({
      reset: new FormControl()
    });
  }

  onReset() {
    this.authService.resetPassword(this.resetPasswordForm.value)
      .then(resetEmailData => {
        this.resetMessage = '';
        this.messageTrigger = true;
        this.resetPassword = false;
        this.loginForm.markAsPristine();
        this.loginForm.markAsUntouched();
        }
      ).catch((error) => {
        this.resetMessage = 'There is no user record corresponding to this email.';
      // console.log(error.code);
      // console.log(error.message);
    });
  }

  onCancel() {
    this.resetPassword = false;
  }

  // ngOnDestroy(): void {
  //   if(this.authSubscription) {
  //     this.authSubscription.unsubscribe();
  //   }
  //   if(this.loadingSubscription) {
  //     this.loadingSubscription.unsubscribe();
  //   }
  // }
}
