<qss-header></qss-header>
<!--<div class="spinner" *ngIf="showSpinner">-->
  <!--<div class="spinner__modal">-->
    <!--<mat-spinner strokeWidth="1"></mat-spinner>-->
  <!--</div>-->
<!--</div>-->
<!--<main [@routeAnimations]="prepareRoute(outlet)">-->
  <!--<router-outlet #outlet="outlet"></router-outlet>-->
<!--</main>-->
<!--<main [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''" class="main-content">-->
  <!--<router-outlet #o="outlet"></router-outlet>-->
<!--</main>-->
<main class="main-content">
  <router-outlet></router-outlet>
</main>
<qss-footer></qss-footer>

