import {Injectable} from '@angular/core';
import {Subscription} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {IDoctor} from './doctor';
import 'rxjs/add/operator/map';
import {map} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import * as fromDoctor from './team.reducer';
import * as Doctor from './team.actions';
import * as UI from '../shared/ui.actions';
import {UIService} from '../shared/ui.service';

@Injectable()

export class DoctorService {

  // private allDoctors: IDoctor[] = [];
  // private doctor: IDoctor;
  private fbSubs: Subscription[] = [];
  // doctorsSubject = new Subject<IDoctor[]>();
  // doctorSubject = new Subject<IDoctor>();

  constructor(
    private afs: AngularFirestore,
    private store: Store<fromDoctor.State>,
    private uiService: UIService) {
  }

  // 1. The below code is the straight forward function to setup a link to database.

  // getDoctors(): Observable<IDoctor[]> {
  //   return this.afs.collection<IDoctor>('doctors').valueChanges();
  // }

  // 2. Even though it is of type IDoctor, it will accept any data. In the html it will
  // give compiler errors if you use a property that is not in IDoctor, but it will still work and display.
  // We will use a pipe which is used to transform / manipulate our data. This is to have CLEAN IDoctor code only.

  // 3. Inside our pipe, lets add a map operator. This will map the data as it goes through the pipe.


  // -------------------------------------------------------------

  // getDoctors(): Observable<IDoctor[]> {
  //   return this.afs.collection<IDoctor>('doctors')
  //     .valueChanges()
  //     .pipe(

  // 4. We are not manipulating the data yet.
  // All we are doing so far is just returning mapped doctors through the pipe.
  // map( doctors => {
  //   return doctors;
  // })
  // );
  // }

  // -------------------------------------------------------------

  // 5. To manipulate the data, we say -> for the list of doctors apply a map function which will take each doctor
  // and convert it to whatever you want, and return it. In the case below the map function will return a single doctor
  // and for each of those doctors we decide and defining what we want to return.
  // I want to return an object containing the various types of data. In the Interface you can define optional with '?'

  // getDoctors(): Observable<IDoctor[]> {
  //   return this.afs.collection<IDoctor>('doctors')
  //     .valueChanges()
  //     .pipe(
  //       map( doctors => {
  //         return doctors.map(doctor => {
  //           return {
  //               key: doctor.key,
  //               doctorId: doctor.doctorId,
  //               doctorName: doctor.doctorName,
  //               doctorSurname: doctor.doctorSurname,
  //               doctorSummary: doctor.doctorSummary,
  //               doctorDate: doctor.doctorDate,
  //               description: doctor.description,
  //               doctorSpecialityTitle: doctor.doctorSpecialityTitle,
  //               doctorSpeciality: doctor.doctorSpeciality,
  //               doctorDegree: doctor.doctorDegree,
  //               doctorDegreeDetail: doctor.doctorDegreeDetail,
  //               doctorTitle: doctor.doctorTitle,
  //               doctorTitleDetail: doctor.doctorTitleDetail,
  //               imageUrl: doctor.imageUrl,
  //               imageUrlAlt: doctor.imageUrlAlt,
  //               doctorIcon: doctor.doctorIcon,
  //               doctorEmail: doctor.doctorEmail
  //           };
  //         });
  //       })
  //     );
  // }

  // 6. As explained earlier, before we ran the data through the 'map function' via the pipe operator,
  // (and provided it was in my database) it would be possible to print out data such as doctor.foo but now
  // that we mapped the data to return what we specifically want, doctor.foo will give the same red squiggly as before,
  // but THIS time doctor.foo will not show up in the front end at all.


  // The only thing missing from the above is since we are using valueChanges(), we are only getting the data inside
  // of our doctor data. We are not getting the metadata (or documentId) which we need to access a specific doctor.
  // Do get all the inside data, as well as the metadata, we need to use snapshotChanges()


  // To use snapshotChanges we need to modify the code a little bit.
  // snapshotChanges is going to return a DocumentChangeAction[] which means instead of mapping into a list of doctors,
  // we are going to map into 'actions'
  // An action contains a 'payload'. A payload contains a document. And a document contains some data.
  // We take the data ( const data ) from the payload.


  getDoctors() {
   this.store.dispatch(new UI.StartLoading());
   this.fbSubs.push(
    this.afs
      .collection('doctors', ref => ref.orderBy('doctorId'))
      .snapshotChanges()
      .pipe(
        map(actions => {
        return actions.map(action => {
          const data = action.payload.doc.data() as IDoctor;
          return {
            id: action.payload.doc.id,
            doctorId: data.doctorId,
            doctorName: data.doctorName,
            doctorSurname: data.doctorSurname,
            doctorSummary: data.doctorSummary,
            doctorDate: data.doctorDate,
            description: data.description,
            doctorSpecialityTitle: data.doctorSpecialityTitle,
            doctorSpeciality: data.doctorSpeciality,
            doctorDegree: data.doctorDegree,
            doctorDegreeDetail: data.doctorDegreeDetail,
            doctorTitle: data.doctorTitle,
            doctorTitleDetail: data.doctorTitleDetail,
            imageUrl: data.imageUrl,
            imageUrlAlt: data.imageUrlAlt,
            doctorIcon: data.doctorIcon,
            doctorEmail: data.doctorEmail
          };
        });
      }))
      .subscribe((doctors: IDoctor[]) => {
        this.store.dispatch(new UI.StopLoading());
        // this.allDoctors = doctors;
        // this.doctorsSubject.next([...this.allDoctors]);
        // Now using Reducer
        this.store.dispatch(new Doctor.SetDoctors(doctors));
      },
        error => {
          this.store.dispatch(new UI.StopLoading());
          this.uiService.showSnackBar(
            'Error Fetching Doctors, please try again later',
            'error--s',
            null,
            3000,
            'error-snack-bar',
            'top');
        }
      ));
  }


  getDoctor(id: string) {
    this.store.dispatch(new UI.StartLoading());
    this.fbSubs.push(
    this.afs.doc<IDoctor>(`doctors/${id}`)
      .snapshotChanges()
      .pipe(
        map(action => {
          const data = action.payload.data() as IDoctor;
          return {
            id: action.payload.id,
            doctorId: data.doctorId,
            doctorName: data.doctorName,
            doctorSurname: data.doctorSurname,
            doctorSummary: data.doctorSummary,
            doctorDate: data.doctorDate,
            description: data.description,
            doctorSpecialityTitle: data.doctorSpecialityTitle,
            doctorSpeciality: data.doctorSpeciality,
            doctorDegree: data.doctorDegree,
            doctorDegreeDetail: data.doctorDegreeDetail,
            doctorTitle: data.doctorTitle,
            doctorTitleDetail: data.doctorTitleDetail,
            imageUrl: data.imageUrl,
            imageUrlAlt: data.imageUrlAlt,
            doctorIcon: data.doctorIcon,
            doctorEmail: data.doctorEmail,
            doctorSocialSummary: data.doctorSocialSummary
          };
        })
      ) .subscribe((doctor: IDoctor) => {
        this.store.dispatch(new UI.StopLoading());
        // Now using Reducer
        // this.doctor = doctor;
        // this.doctorSubject.next(this.doctor);
        this.store.dispatch(new Doctor.SetDoctor(doctor));
      },
      error => {
        this.store.dispatch(new UI.StopLoading());
        this.uiService.showSnackBar(
          'Error Fetching Doctor, please try again later',
          'error--s',
          null,
          3000,
          'error-snack-bar',
          'bottom');
      }));
}

  cancelSubscriptions() {
    this.fbSubs.forEach(sub => sub.unsubscribe());
  }
}

