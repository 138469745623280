// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyA1K3AB_J3cFR5S1CKxw0QmoZYc9PyKTBM',
    authDomain: 'queen-street-surgery-stg.firebaseapp.com',
    databaseURL: 'https://queen-street-surgery-stg.firebaseio.com',
    projectId: 'queen-street-surgery-stg',
    storageBucket: 'queen-street-surgery-stg.appspot.com',
    messagingSenderId: '1000644574464',
    appId: '1:1000644574464:web:1cc2f2ce849b7d30eb87e5'
  },
  googleMapsConfig: {
    apiKey: 'AIzaSyAkcyReFv_UrnledVaOIDyg9gzx6M_d5XM',
  }
};
