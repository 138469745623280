import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../app.reducer';
import {map, take} from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {

  constructor(private authService: AuthService, private router: Router, private store: Store<fromRoot.State>) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    // if(this.authService.isAuth()) {
    //   return true;
    // } else {
    //   this.router.navigate(['/login'])
    // }

    // We now use reducer
    return this.store.select(fromRoot.getIsAuth)
      .pipe(take(1))
      .pipe(map(authUser => {
        if (!authUser) {
          this.router.navigate(['login']);
        } else if (authUser) {
          return authUser;
        }
      }));
  }

  // canLoad we use for lazyModule

  canLoad(route: Route) {

    // if(this.authService.isAuth()) {
    //   return true;
    // } else {
    //   this.router.navigate(['/login'])
    // }

    // We now use reducer
    return this.store.select(fromRoot.getIsAuth)
      .pipe(take(1))
      .pipe(map(authUser => {
        if (!authUser) {
          this.router.navigate(['login']);
        } else if (authUser) {
          return authUser;
        }
      }));
  }
}
