<div class="snack-bar">
  <div class="snack-bar-message">
    <mat-icon svgIcon="{{data.icon}}"></mat-icon>
    <p>
      {{data.message}}
    </p>
  </div>
  <mat-icon class='close' svgIcon="close-circle--s" (click)="snackBarRef.dismiss()"></mat-icon>
</div>

