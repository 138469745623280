import {Action} from '@ngrx/store';
import {IDoctor} from './doctor';

export const SET_DOCTORS = '[Team] Set All Doctors';
export const SET_DOCTOR = '[Team] Set Single Doctor';


export class SetDoctors implements Action {
  readonly type = SET_DOCTORS;

  constructor(public payload: IDoctor[]) {

  }
}

export class SetDoctor implements Action {
  readonly type = SET_DOCTOR;
  constructor(public payload: IDoctor) {

  }
}

export type DoctorActions = SetDoctors | SetDoctor;
