import {
  animate,
  animation, group, query,
  style,
  transition,
  trigger,
  useAnimation
} from '@angular/animations';

// const fadeAnimation = animation([
//   style({
//     opacity: '{{ startOpacity }}'
//   }),
//   animate('{{ duration }}')
// ], {params: {startOpacity: 0, duration: '100ms'}});
//
//
// export const routeFadeStateTrigger =  (params) => trigger('routeFadeState', [
//   transition(':enter', [
//     useAnimation(fadeAnimation, {params: params})
//   ]),
//   transition(':leave', animate(300, style({
//     opacity: 0
//   })))
// ]);
//
//
// export const routeSlideStateTrigger = trigger('routeSlideState', [
//   transition(':enter', [
//     style({
//       transform: 'translateX(-100%)',
//       opacity: 0
//     }),
//     animate(300)
//   ]),
//   transition(':leave', animate(300, style({
//     transform: 'translateX(100%)',
//     opacity: 0
//   })))
// ]);

// export const fader =
//   trigger('routeAnimations', [
//     transition('* <=> *', [
//       // query(':enter, :leave', style({ opacity: 0}), { optional: true }),
//
//
//       query(':enter, :leave', [
//         style({
//           opacity: 0
//         })],
//         { optional: true }
//       ),
//       query(':enter', [
//         animate('600ms ease',
//           style({
//             opacity: 1
//           })
//         ),
//       ],
//         { optional: true }
//       )
//     ]),
//   ]);


export const fadeAnimation = trigger('fadeAnimation', [
  // The '* => *' will trigger the animation to change between any two states
  transition('* => *', [
    // The query function has three params.
    // First is the event, so this will apply on entering or when the element is added to the DOM.
    // Second is a list of styles or animations to apply.
    // Third we add a config object with optional set to true, this is to signal
    // angular that the animation may not apply as it may or may not be in the DOM.
    // query(
    //   ':enter',
    //   [style({ opacity: .5 })],
    //   { optional: true }
    // ),
    // query(
    //   ':leave',
    //   // here we apply a style and use the animate function to apply the style over 0.3 seconds
    //   [style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))],
    //   { optional: true }
    // ),
    // query(
    //   ':enter',
    //   [style({ opacity: 0 }), animate('0.3s', style({ opacity: .5 }))],
    //   { optional: true }
    // )
  ])
]);
