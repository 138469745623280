// export interface State {
//   isLoading: boolean
// }
//
// const initialState = {
//   isLoading: false
// };
//
// export function appReducer(state = initialState, action) {
//   switch (action.type) {
//     case 'START_LOADING':
//       return {
//         isLoading: true
//       };
//     case 'STOP_LOADING':
//       return {
//         isLoading: false
//       };
//     default : {
//       return state;
//     }
//   }
// }


// We have now made a UI Reducer in the shared folder
// This file will rather be used as a Reducer Merger for all the different types of reducers we have


import * as fromUi from './shared/ui.reducer';
import * as fromAuth from './auth/auth.reducer';
import {ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';

export interface State {
  ui: fromUi.State;
  auth: fromAuth.State;
}


export const reducers: ActionReducerMap<State> = {
  ui: fromUi.uiReducer,
  auth: fromAuth.authReducer
};

export const getUiState = createFeatureSelector<fromUi.State>('ui');
// Using this getUiState makes it easier for us to pull info from our state.
// This allows us to call getUiState function and get quick access to 'ui: fromUi.State' state as returned by 'ui: fromUi.uiReducer'
// so gives quick access and spares us having to access state.ui and so on
export const getIsLoading = createSelector(getUiState, fromUi.getIsLoading);
export const getAuthState = createFeatureSelector<fromAuth.State>('auth');
export const getIsAuth = createSelector(getAuthState, fromAuth.getIsAuth);
