import {NgModule} from '@angular/core';
import {ConfirmationModalComponent} from './confirmation-modal.component';
import {MaterialModule} from '../../material.module';
import {SharedModule} from '../shared.module';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({

  declarations: [
    ConfirmationModalComponent,
  ],
  imports: [
    MatDialogModule,
    MaterialModule,
    SharedModule
  ],
  exports: [
    ConfirmationModalComponent,
    MatDialogModule
  ],
  providers: [],
})

export class ConfirmationModalModule {
}
