<div *ngIf="sendMessage"
     @sendState
     class="alert success">
  <div class="alert-headline">
    <h3>
      {{headline}}
    </h3>
    <mat-icon [svgIcon]=iconName class="icon"></mat-icon>
  </div>
  <p>
    {{body}}
  </p>
</div>
