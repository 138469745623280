import {Component, Input, Inject, Output, EventEmitter, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ConfirmationDialogData} from './confirmation.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'qss-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss', '../../user/user-common-styles.scss']
})
export class ConfirmationModalComponent implements OnInit {
  @Output() onBlur = new EventEmitter();
  @Output() onConfirmClick = new EventEmitter();
  @Output() onCancelClick = new EventEmitter();
  @Input() headline: 'Are you sure you want to delete?';
  @Input() body: 'Data will be removed.';
  @Input() actionLabel: 'Yes';
  @Input() headlineIcon: string;
  @Input() cancelLabel: 'No';
  @Input() textArea = false;
  @Input() subject = false;
  @Input() script = false;
  @Input() noCancel = false;
  @Input() textAreaLabel: string;
  @Input() textAreaValue = '';
  @Input() effectiveDate;
  @Input() expirationDate;
  @Input() medicationData = [];
  @Input() placeholder: string;
  @Input() subjectPlaceholder: string;
  textForm: FormGroup;

  constructor( @Inject(MAT_DIALOG_DATA)
               public data: ConfirmationDialogData,
               private fb: FormBuilder,
               private dialogRef: MatDialogRef<ConfirmationDialogData>) {
    this.dialogRef.disableClose = true;
    this.textArea = data.textArea;
    this.script = data.script;
  }

  ngOnInit() {
    this.textForm = this.fb.group({
      subject: new FormControl(null),
      value: new FormControl(this.textAreaValue, Validators.required),
    });
    this.onChanges();
  }

  onChanges(): void {
    this.textForm.valueChanges.subscribe(val => {
      this.textAreaValue = val.value;
    });
  }

  confirmClickHandler() {
    const data = {
      textAreaValue: this.textForm.value.value,
      messageSubject: this.textForm.get('subject').value
    };
    this.onConfirmClick.emit(data);
    this.dialogRef.close('Sent');
  }

  cancelClickHandler() {
    this.onCancelClick.emit();
    this.dialogRef.close();
  }


  get confirmationDisabled(): boolean {
    return this.data.textArea && this.textAreaValue === '';
  }
}
