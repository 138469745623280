import { Injectable } from '@angular/core';

@Injectable()
export class SeoService {

  constructor(
    // private _meta: MetaService,
    // private ngMeta: Meta,
    // private titleService: Title
  ) {
  }



  updateMetaTags(metaTags) {
    metaTags = {
      title: 'Queen Street Surgery',
      description: 'Where Family Meets Care',
      image: 'https://queenstreetsurgery.co.za/assets/img/logo-overlay.jpg',
      twitterImage: 'https://queenstreetsurgery.co.za/assets/img/logo-overlay.jpg',
      slug: '',
    };

    // Set a title

  }
}
