import {Injectable} from '@angular/core';
import {SnackbarComponent} from './snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class UIService {
  constructor(private snackBar: MatSnackBar) {}

  showSnackBar(message, icon, action, duration, panelClass, verticalPosition) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {icon, message},
      duration: duration,
      panelClass: [panelClass, 'qss-snackbar'],
      verticalPosition: verticalPosition
    });
  }
}
