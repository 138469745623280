<div *ngIf="(isAuthing$ | async)" class="loading-auth">
  <div class="screen-loading">
    <div class="qss-loading">
      <svg x="0px" y="0px" viewBox="0 0 100 100" style="width: 73px; margin-right: 5px;">
        <path style="fill: #40b553" d="M94.9,74.4c0,14.1-11.5,25.6-25.6,25.6c-5.5,0-14.3,0-14.8,0c-0.2,0-0.5,0-0.7,0h-21V88.6
    c0.8,0.1,1.6,0.2,2.5,0.2c8.1,0,14.7-6.2,15.3-14.2V62.5H35.3c-17.3,0-31.2-14-31.2-31.2C4.1,14,18.1,0,35.3,0H62
    c11.7,0,21.8,6.4,27.2,15.9v0H35.3c-8.5,0-15.4,6.9-15.4,15.4s6.9,15.4,15.4,15.4h15.4V24.4h15.9v59.2c0.4,0.1,0.9,0.2,1.3,0.3
    c0.5,0.1,1,0.1,1.5,0.1c5.3,0,9.6-4.3,9.6-9.6c0-3.2-1.5-6-3.9-7.8V49.5C86.5,52.1,94.9,62.2,94.9,74.4z"/>
      </svg>
      <h3>Loading</h3>
      <div class="loader"></div>
    </div>
  </div>
</div>
<message-status *ngIf="messageTrigger"
                (close)="messageTrigger= false"
                [headline]="'Check your mail.'"
                [body]="'A link to reset your password has been sent to your email address.'"
                [iconName]="'user--m'">
</message-status>
<div class="auth-wrapper">
  <div class="login-signup"
       *ngIf="!resetPassword"
       [ngClass]="{'valid-form' : loginForm.valid, 'error-form' : hasAuthError }">
    <h2>
      Log In
    </h2>
    <div class="form-error-message" *ngIf="hasAuthError">
      <h4>
        {{errorMessage}}
      </h4>
      <!--<p>-->
      <!--Did you forget your password?-->
      <!--</p>-->
    </div>
    <form class="form"
      [formGroup]="loginForm">
      <mat-form-field appearance="outline">
        <mat-label>Email Address</mat-label>
        <input type="email"
               matInput
               #login="matInput"
               formControlName="email">
        <mat-error *ngFor="let validation of validationMessages.email">
          <mat-error class="error-message"
                     *ngIf="loginForm.get('email').hasError(validation.type) && (loginForm.get('email').dirty ||
                      loginForm.get('email').touched)">
            {{validation.message}}
          </mat-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input type="password"
               matInput
               formControlName="password">
        <mat-error *ngFor="let validation of validationMessages.password">
          <mat-error class="error-message"
                     *ngIf="loginForm.get('password').hasError(validation.type) && (loginForm.get('password').dirty ||
                      loginForm.get('password').touched)">
            {{validation.message}}
          </mat-error>
        </mat-error>
      </mat-form-field>
        <button type="submit"
                class="qss-button--green qss-app"
                (click)="onSubmit()"
                *ngIf="!(isLoading$ | async)"
                [disabled]="loginForm.invalid">
        Log In
        </button>
      <mat-spinner *ngIf="(isLoading$ | async)"></mat-spinner>
    </form>

    <div class="signup-login-link">
      <div class="signup-login-link__action">
        <h4>
          Don't have an account?
        </h4>
        <a class="qss-button--text-only " routerLink="/signup">
          Sign up
        </a>
      </div>
      <div class="signup-login-link__action">
        <h4>
          Forgot your password?
        </h4>
        <a class="qss-button--text-only" (click)="reset()">
          Reset Password
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="resetPassword" class="login-signup">
    <form [formGroup]="resetPasswordForm">
      <h2>Forgot your password?</h2>
      <div class="reset-content">
        <mat-form-field appearance="outline">
          <mat-label>Enter Email Address</mat-label>
          <input matInput
                 formControlName="reset">
        </mat-form-field>
        <mat-error class="error-message"
                   *ngIf="resetMessage">
          {{resetMessage}}
        </mat-error>
        <div class="action-buttons">
          <button type="submit"
                  class="qss-button--text-only"
                  (click)="onCancel()">
            Cancel
          </button>
          <button type="submit"
                  class="qss-button--green"
                  (click)="onReset()">
            Reset Password
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
