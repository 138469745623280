export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyAkcyReFv_UrnledVaOIDyg9gzx6M_d5XM',
    authDomain: 'queen-street-surgery.firebaseapp.com',
    databaseURL: 'https://queen-street-surgery.firebaseio.com',
    projectId: 'queen-street-surgery',
    storageBucket: 'queen-street-surgery.appspot.com',
    messagingSenderId: '476742337698',
    measurementId: 'G-LEKH2JCKNE',
    appId: '1:476742337698:web:2b587be0779106a3afc3fb'
  },
  googleMapsConfig: {
    apiKey: 'AIzaSyAkcyReFv_UrnledVaOIDyg9gzx6M_d5XM',
  }
};
