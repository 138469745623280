import { NgModule } from '@angular/core';
import {HeaderComponent} from './header.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {DropdownDirective} from './dropdown.directive';

@NgModule({

  declarations: [
    HeaderComponent,
    DropdownDirective
  ],
  imports: [
    SharedModule,
    RouterModule
  ],
  exports: [HeaderComponent],
  providers: [],
})

export class HeaderModule {
}
