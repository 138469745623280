import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {LoginComponent} from './login/login.component';
import {SignupComponent} from './signup/signup.component';

const authRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {
      meta: {
        title: 'Login',
        description: ''
      }
    }
  },
  {
    path: 'signup',
    component: SignupComponent,
    data: {
      meta: {
        title: 'Sign Up',
        description: ''
      }
    }
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(authRoutes)
  ],
  exports: [ RouterModule]
})

export class AuthRoutingModule {
}

