import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from './auth/auth.guard';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'team', loadChildren: () => import('./team/team.module').then(m => m.TeamModule) },
  { path: 'history', loadChildren: () => import('./history/history.module').then(m => m.HistoryModule) },
  { path: 'services', loadChildren: () => import('./services/services.module').then(m => m.ServicesModule)  },
  { path: 'fees', loadChildren: () => import('./fees/fees.module').then(m => m.FeesModule) },
  { path: 'hub', canLoad: [AuthGuard], loadChildren: () => import('./user/user-management.module').then(m => m.UserManagementModule) },
  { path: 'notfound', loadChildren: () => import('./page-not-found/404.module').then(m => m.PageNotFoundModule) },
  { path: '**',
    redirectTo: '/notfound'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
