import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import {FooterComponent} from './footer/footer.component';
import {AppRoutingModule} from './app-router.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HeaderModule} from './header/header.module';
import { CommonModule } from '@angular/common';
import {MaterialModule} from './material.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AngularFireFunctionsModule} from '@angular/fire/functions';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireModule} from '@angular/fire';
import {AngularFirePerformanceModule} from '@angular/fire/performance';
import {environment} from '../environments/environment.prod';
import {AuthModule} from './auth/auth.module';
import {AuthService} from './auth/auth.service';
import {MatIconModule} from '@angular/material/icon';
import {StoreModule} from '@ngrx/store';
import {reducers} from './app.reducer';
import {ConfirmationModalModule} from './shared/confirmation-modal/confirmation-modal.module';
import {DoctorService} from './team/doctor.service';
import {UIService} from './shared/ui.service';
import {SnackbarModalModule} from './shared/snackbar/snackbar.module';
import {NgtUniversalModule} from '@ng-toolkit/universal';
import {ConfirmationModalComponent} from './shared/confirmation-modal/confirmation-modal.component';
import {SnackbarComponent} from './shared/snackbar/snackbar.component';
import {UserMessageService} from './user/user-message/user-message.service';
import {AppointmentService} from './user/appointment.service';
import {MedicationService} from './user/medication.service';
import {SeoService} from './shared/seo.service';
import {UserService} from './user/user.service';
import {TransferStateInterceptor} from './transfer-state.interceptor';


@NgModule({
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    SharedModule,
    MaterialModule,
    BrowserAnimationsModule,
    MatIconModule,
    AuthModule,
    AngularFirePerformanceModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    ConfirmationModalModule,
    HeaderModule,
    MatIconModule,
    SnackbarModalModule,
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    NgtUniversalModule,
    BrowserTransferStateModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    })
  ],
  declarations: [
    AppComponent,
    FooterComponent,
  ],
  providers: [
    DoctorService,
    AuthService,
    SeoService,
    UIService,
    UserService,
    UserMessageService,
    MedicationService,
    AppointmentService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TransferStateInterceptor,
      multi: true,
    }],
  entryComponents: [
    ConfirmationModalComponent,
    SnackbarComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
