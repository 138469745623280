import {NgModule} from '@angular/core';
import {SharedModule} from '../shared.module';
import {SnackbarComponent} from './snackbar.component';
import {MatIconModule} from '@angular/material/icon';

@NgModule({

  declarations: [
    SnackbarComponent,
  ],
  imports: [
    SharedModule,
    MatIconModule
  ],
  exports: [
    SnackbarComponent
  ],
  providers: [],
})

export class SnackbarModalModule {
}
