import { NgModule } from '@angular/core';
import {CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../environments/environment';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../material.module';
import {MessageStatusModule} from './message-status/message-status.module';
import {FullDatePipe} from './date.pipe';
import {ParallaxDirective} from './parallax.directive';

@NgModule({
  declarations: [
    FullDatePipe,
    ParallaxDirective,
  ],
  imports: [
    MessageStatusModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MaterialModule,
    AgmCoreModule.forRoot(environment.firebaseConfig)
  ],
  exports: [
    MessageStatusModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AgmCoreModule,
    FullDatePipe,
    MaterialModule,
    ParallaxDirective
  ],
  providers: [],
})

export class SharedModule {
}

