import { NgModule } from '@angular/core';
import {LoginComponent} from './login/login.component';
import {SignupComponent} from './signup/signup.component';
import {AuthRoutingModule} from './auth-routing.module';
import {SharedModule} from '../shared/shared.module';
import {AuthGuard} from './auth.guard';

@NgModule({

  declarations: [
    LoginComponent,
    SignupComponent,
  ],
  imports: [
    AuthRoutingModule,
    SharedModule
  ],
  exports: [
  ],
  providers: [AuthGuard],
})

export class AuthModule {
}

