export const svgList = [
  'qss-logo',
  'angle-left--s',
  'angle-down--s',
  'angle-right--s',
  'angle-up--s',
  'briefcase--m',
  'close--s',
  'close-circle--s',
  'close-circle--m',
  'close--m',
  'degree--m',
  'envelope--s',
  'topher-barker--l',
  'neels-joubert--l',
  'fasie-de-kock--l',
  'dawie-du-plessis--l',
  'lizel-cremer--l',
  'expert-doctor--l',
  'graduate--m',
  'hobbies--m',
  'knysna--l',
  'medical-practice--l',
  'menu-burger--s',
  'patient-care--l',
  'sent-mail--m',
  'qss-mark',
  'user--m',
  'chat--s',
  'cake--s',
  'phone--s',
  'profile--s',
  'email--s',
  'plus--s',
  'check--s',
  'drug--s',
  'drug-check--s',
  'calendar--s',
  'effective-date--s',
  'expiration-date--s',
  'globe-alert--s',
  'stethoscope--m',
  'stethoscope-strike--m',
  'stethoscope--s',
  'stethoscope-check--s',
  'sign-up--l',
  'time--s',
  'gear--s',
  'date--s',
  'drug--m',
  'medical--s',
  'info--s',
  'warn--s',
  'error--s',
  'success--s',
  'bin--s',
  'pencil--s',
  'bin--m',
  'clipboard--s',
  'clipboard--m',
  'clipboard-check--m',
  'clipboard-tick--s',
  'heart-rate--s',
  'dashboard--s',
  'search--s',
  'prescription--s',
  'add-prescription--m',
  'prescription--m',
  'prescription-strike--m',
  'id--s',
  'note--s',
  'cancel-f--s',
  'caret-right--s',
  'caret-left--s',
  'caret-up--s',
  'medical-no--s',
  'med-container--m',
  'hour-glass--s',
  'hour-glass--m',
  'doctor--m',
  'deny--s',
  'doc-paper--l',
  'phone--l',
  'syringe--m',
  'time--m',
  'checkbox--s',
  'reply--s',
  'speech--s',
  'speech--m'
];
