<div class="footer">
  <div class="footer__group">
    <div class="footer__group__list">
      <mat-icon svgIcon="qss-mark" class="qss-logo"></mat-icon>
    </div>
    <div class="footer__group__list">
      <h4>
        Practice
      </h4>
      <ul>
        <li class="footer__group__list-item">
          <a [routerLink]="['/history']">
          History
          </a>
        </li>
        <li class="footer__group__list-item">
          <a [routerLink]="['/team']">
          Our Team
          </a>
        </li>
        <li class="footer__group__list-item">
          <a [routerLink]="['/services']">
          Services
          </a>
        </li>
        <li class="footer__group__list-item">
          <a [routerLink]="['/fees']">
          Fees
          </a>
        </li>
      </ul>
    </div>
    <div class="footer__group__list">
      <h4>
        Resources
      </h4>
      <ul>
        <li class="footer__group__list-item">
          <a href="http://www.knysna.gov.za">
            Knysna
          </a>
        </li>
        <li class="footer__group__list-item">
          <a href="https://www.lifehealthcare.co.za">
           Private Hospital
          </a>
        </li>
        <li class="footer__group__list-item">
          <a href="http://advancedhealth.co.za/knysna-surgical-centre/">
            Advanced Knysna Surgical Centre
          </a>
        </li>
      </ul>
    </div>
    <div class="footer__group__list">
      <h4>
        Connect
      </h4>
      <ul>
        <li class="footer__group__list-item">
          <a [routerLink]="['/home']">
          Contact Us
          </a>
        </li>
        <li class="footer__group__list-item">
          <a href="https://www.facebook.com/QueenStreetSurgery/">
          Facebook Page
          </a>
        </li>

      </ul>
    </div>
  </div>
</div>


