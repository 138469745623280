<div class="confirmation-dialog">
  <mat-dialog-content>
    <div class="dialog-headline">
      <h2>
        {{data.headline}}
      </h2>
      <mat-icon [svgIcon]="headlineIcon"></mat-icon>
    </div>
    <p class="body-text">
      {{data.body}}
    </p>
    <form [formGroup]="textForm" *ngIf="textArea">
      <div class="detail-block-line single-line-field has-subject" *ngIf="data.subject">
        <h5>Subject :</h5>
        <mat-form-field appearance="outline">
          <input matInput
                 [placeholder]="data.subjectPlaceholder"
                 formControlName="subject">
        </mat-form-field>
      </div>
      <div class="detail-block-line single-line-field" [ngClass]="{'has-subject': data.subject}">
        <h5>
          {{data.textAreaLabel}}
        </h5>
        <mat-form-field appearance="outline" class="text-area">
        <textarea matInput
                  cdkAutosizeMinRows="4"
                  cdkTextareaAutosize
                  [value]="textAreaValue"
                  (blur)="onBlur.emit(textForm.value);"
                  formControlName="value"
                  [placeholder]="data.placeholder">
      </textarea>
        </mat-form-field>
      </div>
    </form>
    <div *ngIf="script" class="script-date read-only">
      <div class="detail-block-line">
        <h5>Prescription Date Range</h5>
        <div class="inline-fields">
          <mat-form-field class="user-form-field prefix">
            <mat-label>
              Start Date
            </mat-label>
            <input matInput
                   disabled
                   value="{{data.effectiveDate | fullDate: 'MMMM d, yyyy'}}">
            <mat-icon svgIcon="effective-date--s" matPrefix></mat-icon>
          </mat-form-field>
          <mat-form-field class="user-form-field prefix">
            <mat-label>
              Expiration Date
            </mat-label>
            <input matInput
                   disabled
                   value="{{data.expirationDate | fullDate: 'MMMM d, yyyy'}}">
            <mat-icon svgIcon="expiration-date--s" matPrefix></mat-icon>
          </mat-form-field>
        </div>
      </div>
      <div class="detail-block-line">
        <h5>Prescription Medication</h5>
        <div class="column-icon-list">
          <mat-icon svgIcon="drug--s"></mat-icon>
          <ul>
            <li *ngFor="let medication of data.medicationData; let i = index" class="column-icon-list__item">
              {{i + 1}}. {{medication}}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <div class="action-buttons">
    <button id="noButton"
            *ngIf="!data.noCancel"
            class="qss-button--text-only qss-app"
            (click)="cancelClickHandler()">
      {{data.cancelLabel}}
    </button>
    <button id="yesButton"
            class="qss-button--green qss-app"
            [disabled]="confirmationDisabled"
            (click)="confirmClickHandler()">
      {{data.actionLabel}}
    </button>
  </div>
</div>
