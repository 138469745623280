import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {PasswordValidator} from '../../shared/validators/password-validator';
import {AuthService} from '../auth.service';
import {Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import {ErrorStateMatcher} from '@angular/material/core';

export class ParentErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = !!(form && form.submitted);
    const controlTouched = !!(control && (control.dirty || control.touched));
    const controlInvalid = !!(control && control.invalid);
    const parentInvalid = !!(control && control.parent && control.parent.invalid && (control.parent.dirty || control.parent.touched));

    return isSubmitted || (controlTouched && (controlInvalid || parentInvalid));
  }
}

@Component({
  selector: 'signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  authSubscription: Subscription;
  signupForm: FormGroup;
  parentErrorStateMatcher = new ParentErrorStateMatcher();
  hasAuthError = false;
  errorMessage: string;
  isLoading$: Observable<boolean>;

  validationMessages = {
    'name': [
      { type: 'required', message: 'Name is required' },
      { type: 'minlength', message: 'Name must be at least 5 characters long' },
      { type: 'maxlength', message: 'Name cannot be more than 25 characters long' },
      { type: 'pattern', message: 'Your name must contain only numbers and letters' },
      { type: 'validUsername', message: 'Your name has already been taken' }
    ],
    'email': [
      { type: 'required', message: 'Email is required' },
      { type: 'pattern', message: 'Enter a valid email' }
    ],
    'confirm_password': [
      { type: 'required', message: 'Confirm password is required' },
      { type: 'areEqual', message: 'Password mismatch' }
    ],
    'password': [
      { type: 'required', message: 'Password is required' },
      { type: 'minlength', message: 'Password must be at least 6 characters long' },
      { type: 'pattern', message: 'Your password must contain at least one uppercase, one lowercase, and one number' }
    ],
    'terms': [
      { type: 'pattern', message: 'You must accept terms and conditions' }
    ]
  };


  constructor(private authService: AuthService,
              private store: Store<{ui: fromRoot.State}>) {
  }

  ngOnInit() {

    // this.loadingSubscription = this.uiService.loadingStateChanged.subscribe(isLoading => {
    //   this.isLoading = isLoading;
    // });
    this.isLoading$ = this.store.select(fromRoot.getIsLoading);

    this.signupForm = new FormGroup({
      name: new FormControl('', {
        validators: [Validators.required]
      }),
      surname: new FormControl('', {
        validators: [Validators.required]
      }),
      email: new FormControl('', {
        validators: [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]
      }),

      matchingPasswords : new FormGroup({
        password: new FormControl('', Validators.compose([
          Validators.minLength(6),
          Validators.required
        ])),
        confirmPassword: new FormControl('', Validators.required)
      }, (formGroup: FormGroup) => {
        return PasswordValidator.areEqual(formGroup);
      }),
    });
  }

  onSubmit() {

    const capitalize = (s) => {
      if (typeof s !== 'string') { return ''; }
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    const roles = {
      admin: false,
      patient: true
    };

    const data = {
      name: capitalize(this.signupForm.get('name').value),
      surname: capitalize(this.signupForm.get('surname').value),
      email: this.signupForm.get('email').value,
      password: this.signupForm.get('matchingPasswords').get('password').value,
      confirmPassword: this.signupForm.get('matchingPasswords').get('confirmPassword').value,
      roles: roles
    };

    this.authService.registerUser({
      email: this.signupForm.value.email,
      password: this.signupForm.get('matchingPasswords').value.password
    }, data);

    this.authSubscription = this.authService.authErrorMessage.subscribe( authMessage => {
      this.errorMessage = authMessage;
    });

    this.authSubscription = this.authService.authError.subscribe( authStatus => {
      this.hasAuthError = authStatus;
      if (this.hasAuthError) {
        this.signupForm.reset();
      }
    });
  }

  // ngOnDestroy(): void {
  //   if(this.authSubscription) {
  //     this.authSubscription.unsubscribe();
  //   }
  //   if(this.loadingSubscription) {
  //     this.loadingSubscription.unsubscribe();
  //   }
  // }
}
