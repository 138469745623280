import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../app.reducer';
import {from, Observable, Observer, Subject, Subscription} from 'rxjs';
import * as UI from '../shared/ui.actions';
import {bufferCount, concatMap, first, map} from 'rxjs/operators';
import {AngularFirestore, AngularFirestoreCollection, QueryDocumentSnapshot} from '@angular/fire/firestore';
import {IUser} from './user.model';
import {AuthService} from '../auth/auth.service';
import {IMedication} from './medication.model';
import {UserMessageService} from './user-message/user-message.service';
import moment from 'moment';
import {AngularFireFunctions} from '@angular/fire/functions';

@Injectable()
export class MedicationService {
  userData: IUser;
  createMessageSubject = new Subject<boolean>();
  statusType = new Subject<string>();
  medication: IMedication;
  allMedications: IMedication[] = [];
  allPrescriptions: IMedication[] = [];
  medicationSubject = new Subject<IMedication>();
  medicationsSubject = new Subject<IMedication[]>();
  prescriptionSubject = new Subject<IMedication[]>();
  private fbSubs: Subscription[] = [];

  constructor(private store: Store<{ ui: fromRoot.State }>,
              private auth: AuthService,
              private fns: AngularFireFunctions,
              private _surgeryMessageService: UserMessageService,
              private afs: AngularFirestore) {
    this.auth.user$.subscribe(user => {
      this.userData = user;
    });
  }

  createPrescription(prescriptionData: IMedication, patientData: IUser) {
    const medicationArray = prescriptionData.medication.map(item => item.charAt(0).toUpperCase() + item.substr(1).toLowerCase())
    const prescription = {
      expirationDate: moment(prescriptionData.expirationDate).toDate(),
      effectiveDate: prescriptionData.effectiveDate,
      medication: medicationArray,
      patientId: prescriptionData.patientId,
      name: patientData.name,
      surname: patientData.surname,
      phone: patientData.phone,
      email: patientData.email,
    };
    return this.afs
      .collection<IMedication>('users')
      .doc(`${prescriptionData.patientId}`)
      .collection('prescription')
      .add(prescription)
      .catch()
      .then((docRef) => {
        this.createMessageSubject.next(true);
        this.initCronJob(prescriptionData, patientData)
          .catch()
          .then(() => {
            this.statusType.next('Prescription');
            const messageData = {
              name: patientData.name,
              surname: patientData.surname,
              phone: patientData.phone,
              email: 'pharmacy@queenstreetsurgery.co.za',
              emailDate: new Date(),
              messageTitle: `Your prescription has been initiated.`,
              messageType: 'alerts',
              patientMessage: null,
              cancelReason: null,
              adminResponse: `A prescription has been added to your profile.
              You now have the ability to order medication from this prescription.`,
              messageSubject: 'Prescription Initiated',
              status: 'Prescription',
              sendTo: patientData.email,
              patient: false,
              refDoc: docRef.id
            };
            this._surgeryMessageService.sendMessage(messageData);
          });
      });
  }

  initCronJob(prescriptionData: IMedication, patientData: IUser) {
    const cronJobData = {
      options: {
        userId: prescriptionData.patientId,
        name: patientData.name,
        surname: patientData.surname,
        phone: patientData.phone,
        email: patientData.email,
        emailDate: new Date(),
      },
      performAt: prescriptionData.expirationDate,
      status: 'scheduled',
      worker: 'deletePrescription'
    };


    return this.afs
      .collection('tasks')
      .add(cronJobData)
      .catch()
      .then(() => {});
  }

  async deleteCollection(collection: AngularFirestoreCollection<any>): Promise<number> {
    let totalDeleteCount = 0;
    const batchSize = 500;

    return new Promise<number>((resolve, reject) =>
      from(collection.ref.get())
        .pipe(
          concatMap((q) => from(q.docs)),
          bufferCount(batchSize),
          concatMap((docs: Array<QueryDocumentSnapshot<any>>) => new Observable((o: Observer<number>) => {
            const batch = this.afs.firestore.batch();
            docs.forEach((doc) => batch.delete(doc.ref));
            batch.commit()
              .then(() => {
                o.next(docs.length);
                o.complete();
              })
              .catch((e) => o.error(e));
          })),
        )
        .subscribe(
          (batchDeleteCount: number) => totalDeleteCount += batchDeleteCount,
          (e) => reject(e),
          () => resolve(totalDeleteCount),
        ),
    );
  }

   getPrescriptions(userId) {
    this.store.dispatch(new UI.StartLoading());
    this.fbSubs.push(
      this.afs
        .collection<IMedication>(`users/${userId}/prescription`)
        .snapshotChanges()
        .pipe(
          map(actions => {
            return actions.map(action => {
              const data = action.payload.doc.data() as IMedication;
              return {
                patientId: action.payload.doc.id,
                effectiveDate: data.effectiveDate,
                expirationDate: data.expirationDate,
                medication: data.medication
              };
            });
          })
        )
        .subscribe((medications: IMedication[]) => {
          this.store.dispatch(new UI.StopLoading());
          this.allPrescriptions = medications;
          this.prescriptionSubject.next([...this.allPrescriptions]);
        }));
  }

  getOrderedMedication() {
    this.store.dispatch(new UI.StartLoading());
    this.fbSubs.push(
      this.auth.user$.subscribe(user => {
        this.userData = user;
        this.fbSubs.push(
          this.afs
            .collectionGroup<IMedication>('medications')
            .snapshotChanges()
            .pipe(
              map(actions => {
                return actions.map(action => {
                  const data = action.payload.doc.data() as IMedication;
                  return {
                    medicationId: action.payload.doc.id,
                    renewInformation: data.renewInformation,
                    medicationResponse: data.medicationResponse,
                    medication: data.medication,
                    status: data.status,
                    cancelReason: data.cancelReason,
                    declineReason: data.declineReason,
                    requestDate: data.requestDate,
                    userData: data.userData,
                    adminStatus: data.adminStatus,
                    patientStatus: data.patientStatus,
                  };
                });
              })
            )
            .subscribe((medications: IMedication[]) => {
              this.store.dispatch(new UI.StopLoading());
              this.allMedications = medications;
              this.medicationsSubject.next([...this.allMedications]);
              // Now using Reducer
              // this.store.dispatch(new Doctor.SetDoctors(doctors))
            }));
      }));
  }

  getOrderedMedicationDetail(id: string, status: string) {
    this.store.dispatch(new UI.StartLoading());
    this.fbSubs.push(
      this.afs
        .doc<IMedication>(`medications/${status}/medications/${id}`)
        .snapshotChanges()
        .pipe(
          map(action => {
            const data = action.payload.data() as IMedication;
            return {
              medicationId: action.payload.id,
              renewInformation: data.renewInformation,
              medicationResponse: data.medicationResponse,
              declineReason: data.declineReason,
              medication: data.medication,
              status: data.status,
              cancelReason: data.cancelReason,
              requestDate: data.requestDate,
              adminStatus: data.adminStatus,
              patientStatus: data.patientStatus,
              userData: data.userData,
            };
          })
        )
        .subscribe((medication: IMedication) => {
          this.store.dispatch(new UI.StopLoading());
          this.medication = medication;
          this.medicationSubject.next(this.medication);
        }));
  }

  orderMedication(medicationData: IMedication) {
    const orderDrug = {
      effectiveDate: medicationData.effectiveDate,
      expirationDate: medicationData.expirationDate,
      status: medicationData.status,
      patientStatus: 'Pending',
      requestDate: medicationData.requestDate,
      renewInformation: medicationData.renewInformation,
      medication: medicationData.medication,
      adminStatus: 'Pending',
      userData: {
        userId: this.userData.userId,
        name: this.userData.name,
        surname: this.userData.surname,
        phone: this.userData.phone,
        doctor: this.userData.doctor,
        email: this.userData.email,
        birthday: this.userData.birthday.toDate()
      }
    };
    this.afs
      .collection<IMedication>('medications')
      .doc(`${medicationData.status}`)
      .collection('medications')
      .add(orderDrug)
      .catch()
      .then((docRef) => {
        this.statusType.next('Order');
        const messageData = {
          name: orderDrug.userData.name,
          surname: orderDrug.userData.surname,
          phone: orderDrug.userData.phone,
          email: orderDrug.userData.email,
          emailDate: new Date(),
          messageTitle: `Medication Request from ${orderDrug.userData.name + ' ' + orderDrug.userData.surname}`,
          messageType: 'alerts',
          patientMessage: orderDrug.renewInformation,
          cancelReason: null,
          adminResponse: null,
          adminStatus: 'Pending Medication',
          messageSubject: 'Medication Renewal Request',
          status: 'Pending',
          sendTo: 'pharmacy@queenstreetsurgery.co.za',
          patient: true,
          refDoc: docRef.id
        };
        this._surgeryMessageService.sendMessage(messageData);

        this.fns.httpsCallable('genericEmail')
        ({
          title: 'Medication Request from',
          name: orderDrug.userData.name,
          surname: orderDrug.userData.surname,
          email: orderDrug.userData.email,
          subject: 'Medication Request',
          msgBody: 'A medication request has been made and an email also has been sent to the pharmacy. Please log into the app to see the request.',
        })
          .toPromise()
          .then((response) => console.log(response))
          .catch((err) => console.error('error', err));
      });
  }


  approveMedicationOrder(submitApprovedMedicationData: IMedication, medicationData: IMedication) {
    const approvedMedicationData = {
      status: medicationData.status,
      medicationResponse: medicationData.medicationResponse,
      medication: submitApprovedMedicationData.medication,
      cancelReason: null,
      renewInformation: submitApprovedMedicationData.renewInformation,
      requestDate: submitApprovedMedicationData.requestDate,
      userData: submitApprovedMedicationData.userData,
      medicationId: submitApprovedMedicationData.medicationId,
    };
    this.afs
      .collection('medications')
      .doc<IMedication>(`Pending`)
      .collection('medications')
      .doc<IMedication>(`${submitApprovedMedicationData.medicationId}`)
      .delete()
      .catch()
      .then(() => {
        this.createMessageSubject.next(true);

        const approveData = {
          renewInformation: approvedMedicationData.renewInformation,
          medicationResponse: approvedMedicationData.medicationResponse,
          medication: approvedMedicationData.medication,
          requestDate: approvedMedicationData.requestDate,
          cancelReason: approvedMedicationData.cancelReason,
          status: 'Approved',
          adminStatus: 'Approved',
          patientStatus: 'Approved',
          userData: approvedMedicationData.userData,
        };

        const approveMessage = {
          name: approvedMedicationData.userData.name,
          surname: approvedMedicationData.userData.surname,
          phone: approvedMedicationData.userData.phone,
          email: 'pharmacy@queenstreetsurgery.co.za',
          emailDate: new Date(),
          adminResponse: approvedMedicationData.medicationResponse,
          messageTitle: 'Medication Request Approved',
          patientMessage: submitApprovedMedicationData.renewInformation,
          cancelReason: approvedMedicationData.cancelReason,
          messageType: 'alerts',
          adminStatus: 'Approve Medication',
          messageSubject: 'Your Medication Has Been Approved',
          status: 'Approve',
          sendTo: approvedMedicationData.userData.email,
          patient: false,
          refDoc: approvedMedicationData.medicationId,
        };
        this.afs
          .collection('medications')
          .doc('Approved')
          .collection('medications')
          .add(approveData)
          .then(() => {
            this._surgeryMessageService.sendMessage(approveMessage);
          });
      });
  }

  declineMedication(declinedMedication: IMedication) {
    this.afs
      .collection('medications')
      .doc('Pending')
      .collection('medications')
      .doc<IMedication>(`${declinedMedication.medicationId}`)
      .delete()
      .catch()
      .then((docRef) => {
        this.createMessageSubject.next(true);
        const declineData = {
          renewInformation: declinedMedication.renewInformation,
          medicationResponse: declinedMedication.declineReason,
          medication: declinedMedication.medication,
          requestDate: declinedMedication.requestDate,
          adminStatus: 'Declined',
          patientStatus: 'Declined',
          status: 'Declined',
          cancelReason: declinedMedication.cancelReason,
          userData: declinedMedication.userData,
        };
        const declineMessage = {
          name: declinedMedication.userData.name,
          surname: declinedMedication.userData.surname,
          phone: declinedMedication.userData.phone,
          email: 'pharmacy@queenstreetsurgery.co.za',
          emailDate: new Date(),
          messageTitle: 'Medication Request Declined',
          patientMessage: declinedMedication.renewInformation ? declinedMedication.renewInformation : null,
          cancelReason: declinedMedication.cancelReason ? declinedMedication.cancelReason : null,
          adminResponse: declinedMedication.declineReason ? declinedMedication.declineReason : null,
          messageType: 'alerts',
          adminStatus: 'Declined Medication',
          messageSubject: 'Your Medication Request Has Been Declined',
          status: 'Decline',
          sendTo: declinedMedication.userData.email,
          patient: false,
          refDoc: declinedMedication.medicationId,
        };
        this.afs
          .collection('medications')
          .doc('Declined')
          .collection('medications')
          .add(declineData)
          .then(() => {
            this._surgeryMessageService.sendMessage(declineMessage);
          });
      });
  }

cancelOrderedMedicationDetail(cancelMedicationData) {
  const canceledMedicationData = {
    status: 'Cancelled',
    cancelReason: cancelMedicationData.cancelReason,
    medication: cancelMedicationData.medication,
    renewInformation: cancelMedicationData.renewInformation,
    medicationResponse: cancelMedicationData.medicationResponse,
    requestDate: cancelMedicationData.requestDate,
    userData: cancelMedicationData.userData,
    medicationId: cancelMedicationData.medicationId,
    adminStatus: cancelMedicationData.adminStatus,
    patientStatus: 'Cancelled'
  };
  return this.afs
    .collection('medications')
    .doc(`${cancelMedicationData.status}`)
    .collection('medications')
    .doc<IMedication>(`${cancelMedicationData.medicationId}`)
    .delete()
    .catch()
    .then(() => {
      this.statusType.next('Cancel');
      this.afs
        .collection('medications')
        .doc('Cancelled')
        .collection('medications')
        .add(canceledMedicationData)
        .then(() => {
          const cancelMessage = {
            name: cancelMedicationData.userData.name,
            surname: cancelMedicationData.userData.surname,
            phone: cancelMedicationData.userData.phone,
            email: cancelMedicationData.userData.email,
            emailDate: new Date(),
            messageType: 'alerts',
            messageTitle: 'Medication Request Cancelled',
            patientMessage: cancelMedicationData.renewInformation,
            cancelReason: cancelMedicationData.cancelReason,
            adminResponse: cancelMedicationData.medicationResponse,
            patientStatus: 'Cancelled Medication',
            adminStatus: 'Cancelled Medication',
            messageSubject: `Medication Request Cancelled`,
            status: 'Cancelled',
            sendTo: 'pharmacy@queenstreetsurgery.co.za',
            refDoc: cancelMedicationData.medicationId,
            patient: true,
          };
          this._surgeryMessageService.sendMessage(cancelMessage);
        });
    });
}

  adminDeleteMedication(medication: IMedication): Promise<void> {
    const deleteMedicationData = {
      requestDate: medication.requestDate,
      medication: medication.medication,
      renewInformation: medication.renewInformation,
      status: 'Deleted',
      patientStatus: medication.patientStatus,
      medicationId: medication.medicationId,
      medicationResponse: medication.medicationResponse,
      cancelReason: medication.cancelReason,
      adminStatus: 'Deleted',
      userData: medication.userData
    };


    if (medication.status === 'Deleted') {
      return this.afs
        .collection('medications')
        .doc(`${medication.status}`)
        .collection('medications')
        .doc<IMedication>(`${medication.medicationId}`)
        .set(deleteMedicationData, {merge: true});
    } else {
      return this.afs
        .collection('medications')
        .doc(`${medication.status}`)
        .collection('medications')
        .doc<IMedication>(`${medication.medicationId}`)
        .delete()
        .catch()
        .then(() => {
          this.statusType.next('Delete');
          this.afs
            .collection('medications')
            .doc('Deleted')
            .collection('medications')
            .add(deleteMedicationData)
            .then(() => {
            });
        });
    }
  }

  deleteMedication(medication: IMedication): Promise<void> {
    const deleteMedicationData = {
      status: 'Deleted',
      cancelReason: medication.cancelReason,
      medication: medication.medication,
      renewInformation: medication.renewInformation,
      medicationResponse: medication.medicationResponse,
      requestDate: medication.requestDate,
      userData: medication.userData,
      medicationId: medication.medicationId,
      adminStatus: medication.adminStatus,
      patientStatus: 'Deleted'
    };
    return this.afs
      .collection('medications')
      .doc(`${medication.status}`)
      .collection('medications')
      .doc<IMedication>(`${medication.medicationId}`)
      .delete()
      .catch()
      .then(() => {
        this.statusType.next('Delete');
        this.afs
          .collection('medications')
          .doc('Deleted')
          .collection('medications')
          .add(deleteMedicationData)
          .then(() => {
          });
      });
  }


  notifyCollectPatient(requestingPatient, medicationId) {
    const updatedData = {
      adminStatus: 'Completed'
    };
    this.fns.httpsCallable('sendUserWhatsApp')({
      message: `*Queen Street Surgery:* Hi, ${requestingPatient.name}! Your medication is ready for collection. Please go to the surgery to pick it up.`,
      phoneNumber: requestingPatient.phone
    }).pipe(first())
      .subscribe(resp => {
        // console.log({ resp });
      }, err => {
        console.error({ err });
      });
    return this.afs
      .collection('medications')
      .doc(`Approved`)
      .collection('medications')
      .doc<IMedication>(`${medicationId}`)
      .update(updatedData);
  }

  cancelSubscriptions() {
    this.fbSubs.forEach(sub => sub.unsubscribe());
  }
}
