import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Subject, Subscription} from 'rxjs';
import 'rxjs/add/operator/map';
import * as UI from '../../shared/ui.actions';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import {map} from 'rxjs/operators';
import {AuthService} from '../../auth/auth.service';
import {IUser} from '../user.model';
import {DatePipe} from '@angular/common';
import {AngularFireAuth} from '@angular/fire/auth';
import {Message} from '../message.model';

@Injectable()
export class UserMessageService {

  private messages: Message[] = [];
  messageSubject = new Subject<Message[]>();
  emitMessage = new Subject<boolean>();
  datePipeEn: DatePipe = new DatePipe('en-US');
  private fbSubs: Subscription[] = [];
  createMessageSubject = new Subject<boolean>();

  constructor(private afs: AngularFirestore,
              private afAuth: AngularFireAuth,
              private store: Store<{ui: fromRoot.State}>) {

  }

  getSurgeryMessages() {
    this.store.dispatch(new UI.StartLoading());
    this.fbSubs.push(
      this.afs
        .collectionGroup<Message>('messages', ref => {
          return ref
            .orderBy('emailDate', 'desc');
        })
        .snapshotChanges()
        .pipe(
          map(actions => {
            return actions.map(action => {
              const data = action.payload.doc.data() as Message;
              return {
                messageId: action.payload.doc.id,
                patientMessage: data.patientMessage,
                adminResponse: data.adminResponse,
                messageSubject: data.messageSubject,
                messageTitle: data.messageTitle,
                messageType: data.messageType,
                emailDate: data.emailDate,
                email: data.email,
                phone: data.phone,
                name: data.name,
                surname: data.surname,
                sendTo: data.sendTo,
                status: data.status,
                patient: data.patient,
                patientStatus: data.patientStatus,
                adminStatus: data.adminStatus,
              };
            });
          }))
        .subscribe((messages: Message[]) => {
          this.store.dispatch(new UI.StopLoading());
          this.messages = messages;
          this.messageSubject.next([...this.messages]);
        }));
  }

  stringGen(len) {
    let text = '';
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    for (let i = 0; i < len; i++) {
      text += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return text;
  }

  sendMessage(message: Message) {
    const data = {
      name: message.name,
      surname: message.surname,
      phone: message.phone ? message.phone : null,
      email: message.email,
      emailDate: message.emailDate,
      messageTitle: message.messageTitle,
      messageType: message.messageType,
      patientMessage: message.patientMessage ? message.patientMessage : null,
      cancelReason: message.cancelReason ? message.cancelReason : null,
      adminResponse: message.adminResponse ? message.adminResponse : null,
      messageSubject: message.messageSubject,
      status: message.status,
      sendTo: message.sendTo,
      patient: message.patient,
      patientStatus: message.patientStatus ? message.patientStatus : null,
      adminStatus: message.adminStatus ? message.adminStatus : null,
      refDoc: message.refDoc ? message.refDoc : (this.stringGen(20))
    };
      this.afs
        .collection<Message>('messages')
        .doc(`${data.messageType}`)
        .collection(`messages`)
        .doc(`${data.refDoc}`)
        .set(data)
        .catch()
        .then(() => {
          this.emitMessage.next(true);
        });
  }

  trashMessage(message: Message) {
    this.afs
      .collection<Message>('messages')
      .doc(`${message.messageType}`)
      .collection(`messages`)
      .doc(`${message.refDoc}`)
      .set(message)
      .catch();
      // .then(() => {
      //   this.emitMessage.next(true);
      // });
  }

  deletePatientMessage(message: Message): Promise<void> {
    return this.afs
      .collection<Message>('messages')
      .doc(`${message.messageType}`)
      .collection(`messages`)
      .doc(`${message.refDoc}`)
      .delete()
      .catch()
      .then(() => {
        this.emitMessage.next(true);
      });
  }

  cancelSubscriptions() {
    this.fbSubs.forEach(sub => sub.unsubscribe());
  }
}
