import {Directive, Input, ElementRef, HostListener, Renderer2, OnInit, Inject} from '@angular/core';
import {WINDOW} from '@ng-toolkit/universal';

@Directive({
  selector: '[appParallax]'
})
export class ParallaxDirective {

  @Input('ratio') parallaxRatio = 1;
  @Input() initialTop = 0;

  constructor(@Inject(WINDOW) private window: Window, private eleRef: ElementRef, private renderer: Renderer2) {
    this.initialTop = this.eleRef.nativeElement.getBoundingClientRect().top;
    // this.eleRef.nativeElement.style.top = (this.initialTop) + 'px';
  }


  private getSize() {
    return (this.initialTop - (this.window.scrollY * this.parallaxRatio));
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    this.eleRef.nativeElement.style.top = (this.initialTop - (this.window.scrollY * this.parallaxRatio)) + 'px';
    // this.renderer.setStyle(this.eleRef.nativeElement, 'transform', 'translate3d(0, '+this.getSize()+'px, 0px');
  }
}
