import { Injectable } from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {AuthData} from './auth.model';
import {AngularFireAuth} from '@angular/fire/auth';
import {IUser} from '../user/user.model';
import {UIService} from '../shared/ui.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../app.reducer';
import * as UI from '../shared/ui.actions';
import * as Auth from './auth.actions';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {switchMap} from 'rxjs/operators';
import {UserMessageService} from '../user/user-message/user-message.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class AuthService {

  // A Subject is the same as an EventEmitter. It is an object that allows you to emits event and
  // subscribe to it in different parts of the app.

  // Every time we register a user I want to emit an event.
  // Add a public (non private) property. A subject is a generic Type which means it can
  // hold various payloads of generic type, in our case : a boolean

  authError = new Subject<boolean>();
  // TODO Add Error message to Reducer too
  authErrorMessage = new Subject<string>();
  user$: Observable<IUser>;
  // We now use our reducer
  // authChange = new Subject<boolean>();
  // private isAuthenticated = false;

  constructor(private router: Router,
              private afAuth: AngularFireAuth,
              private afs: AngularFirestore,
              private snackBar: MatSnackBar,
              private _surgeryMessageService: UserMessageService,
              private uiService: UIService,
              private store: Store<{ui: fromRoot.State}>) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<IUser>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );

    // this.qssAppointment = this.afs.collection<IAppointment>(`users/${user.uid}`);
    // // this.messages$ = this.qssCollection.valueChanges();
    // this.appointmentData = this.qssAppointment.snapshotChanges().map(changes => {
    //   return changes.map(a => {
    //     const data = a.payload.doc.data() as IAppointment;
    //     data.appointmentId = a.payload.doc.id;
    //     return data;
    //   });
    // });
  }

  // makeAppointment(appointmentData: IAppointment) {
  //   // return this.afs.doc<IUser>(`users/${this.user$.}`)
  // }


  // TODO 10 Sep I was getting error of undefined user data when auto login.
  // TODO This fixed it, but when I removed it, it still worked. Keep eye on it.
  // get authState(): Observable<any> {
  //   return this.afAuth.authState;
  // }


  initAuthListener() {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        // this.isAuthenticated = true;
        // this.authChange.next(true);
        // We now use our reducer
        this.store.dispatch(new Auth.SetAuthenticated());
        this.router.navigate(['/hub']);
      } else {
        // this.authChange.next(false);
        // this.isAuthenticated = false;
        // We now use our reducer
        // this.teamService.cancelSubscriptions();
        this.store.dispatch(new Auth.SetUnauthenticated());
        this.router.navigate(['/home']);
      }
    });
  }

  registerUser(authData: AuthData, userData: IUser) {
    // this.user = {
    //   email: authData.email,
    //   userId: Math.round(Math.random() * 10000).toString()
    // };
    // this.authSuccessfully();


    // authChange will trigger every time an auth is created.
    // but this time its not "emit", but next.
    // we pass true into the next()


    // Instead of using our uiService, we use our app reducer store
    // this.uiService.loadingStateChanged.next(true);

    // We have further refactoring the below to take in our special action, rather than string
    // this.store.dispatch({type: 'START_LOADING'});
    this.store.dispatch(new UI.StartLoading());

    this.afAuth.createUserWithEmailAndPassword(
      authData.email,
      authData.password
    ).then(user => {
      // this.uiService.loadingStateChanged.next(false);
      // this.store.dispatch({type: 'STOP_LOADING'});
      this.store.dispatch(new UI.StopLoading());
      // this.authSuccessfully();
      this.SetUserData(user.user, userData);
    })
      .catch(error => {
        // this.uiService.loadingStateChanged.next(false);
        // this.store.dispatch({type: 'STOP_LOADING'});
        this.store.dispatch(new UI.StopLoading());
        this.authFailed(error.message);

        // this.snackBar.open(error.message, null, {
        //   duration : 3000
        // })

        // Refactored the snackbar to use it from the UIService
        // this.uiService.showSnackBar(error.message, null, 3000);
      });
  }

  login(authData: AuthData) {
    // this.user = {
    //   email: authData.email,
    //   userId: Math.round(Math.random() * 10000).toString()
    // };

    // this.uiService.loadingStateChanged.next(true);
    // this.store.dispatch({type: 'START_LOADING'});
    this.store.dispatch(new UI.StartLoading());
    this.afAuth.signInWithEmailAndPassword(
      authData.email,
      authData.password
    ).then(user => {
      // this.uiService.loadingStateChanged.next(false);
      // this.store.dispatch({type: 'STOP_LOADING'});
      this.store.dispatch(new UI.StopLoading());
      // debugger;
      // return this.loginUserData(userData);
      return this.loginUserData(user);
      // this.authSuccessfully();
    })
      .catch(error => {
        // this.uiService.loadingStateChanged.next(false);
        // this.store.dispatch({type: 'STOP_LOADING'});
        this.store.dispatch(new UI.StopLoading());
        this.authFailed(error.message);
      });
  }

  logout() {
    this.afAuth.signOut();
    // This doest work for some reason. Fireship
    // return this.router.navigate(['/'])
  }

  resetPassword(resetEmail): Promise<void> {
   return this.afAuth.sendPasswordResetEmail(resetEmail.reset);
  }

  // getUser() {
  //   // Because this is a "Reference Type Object", other parts of the app could actually change the 'this.user' object in the service.
  //   // return this.user;
  //
  //   // To prevent this, we wrap it in a new Object and use the "Object Spread Operator" to spread
  //   the property of the User Object stored in the service, into this new object.
  //   // This will break this reference and return a brand new User that has the same properties but is a different object.
  //   // So when parts of the app start manipulating the user below, they wont be also manipulating the initial user at the top
  //   return { ...this.user};
  //
  //   // Since using AngularAuth we no longer use this whole function
  // }

  // Refactored the below into initAuthListener()

  // private authSuccessfully() {
  //   this.isAuthenticated = true;
  //   this.authChange.next(true);
  //   this.router.navigate(['/patient']);
  // }

  private authFailed(errorMessage: string) {
    this.authError.next(true);
    this.authErrorMessage.next(errorMessage);
  }


  SetUserData(user, userData) {
    const userRef: AngularFirestoreDocument<IUser> = this.afs.doc(`users/${user.uid}`);
    const data: IUser = {
      userId: user.uid,
      email: user.email,
      name: userData.name,
      surname: userData.surname,
      photoURL: '',
      roles: {
        patient: true
      }
    };

    const messageData = {
      name: userData.name,
      surname: userData.surname,
      phone: 'N/A',
      email: user.email,
      emailDate: new Date(),
      messageTitle: 'Message from new user',
      messageType: 'alerts',
      patientMessage: 'A new user has joined',
      cancelReason: null,
      adminResponse: null,
      messageSubject: `New User Joined`,
      status: 'New User',
      sendTo: 'war.wick101@gmail.com',
      adminStatus: 'New User',
      patientStatus: null,
      patient: true,
    };

    this._surgeryMessageService.sendMessage(messageData);
    return userRef.set(data);
  }

  loginUserData(user) {
    const userRef: AngularFirestoreDocument<IUser> = this.afs.doc(`users/${user.uid}`);
    // uid or any of the data below is undefined. This is how I fixed that warning about
    // uid in field. Look into this to see why this is all undefined and if it can be removed? Look at tut.
    const data: IUser = {
      // uid: user.uid,
      email: user.email,
      name: user.name,
      surname: user.surname,
      photoURL: user.photoURL,
      roles: {
        patient: true
      }
    };
    return userRef.set(data, {merge: true});
  }


  // Assign roles to an ability method

  canRead(user: IUser): boolean {
    const allowed = ['admin', 'patient'];
    return this.checkAuthorization(user, allowed);
  }

  canEdit(user: IUser): boolean {
    const allowed = ['admin'];
    return this.checkAuthorization(user, allowed);
  }

  patientOnly(user: IUser): boolean {
    const allowed = ['patient'];
    return this.checkAuthorization(user, allowed);
  }

  // Determines if user has matching role

  private checkAuthorization(user: IUser, allowedRoles: string[]): boolean {
    if (!user) { return false; }
    for (const role of allowedRoles) {
      if ( user.roles[role] ) {
        return true;
      }
    }
    return false;
  }

  // We now can remove this whole method (which was used in the Router Guard, because we use reducer
  // isAuth(){
  //
  //   // If it is not equal to null, then the user Authentication returns true, if it is null then it will return false.
  //   // return this.user != null;
  //
  //   // Since using AngularAuth we no longer use this whole function
  //   return this.isAuthenticated;
  // }
}
