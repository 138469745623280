<div class="header">
  <div class="content--container header__nav">
      <a [routerLink]="['/home']" class="header__nav-logo">
        <mat-icon svgIcon="qss-logo"></mat-icon>
      </a>
      <ul class="header__nav-list">
        <!--<a *ngFor="let routeItem of routeItems" [routerLink]="[routeItem.routerLink]"-->
           <!--routerLinkActive="router-active">-->
          <!--{{routeItem.page}}-->
        <!--</a>-->

        <a [routerLink]="['/home']"
           routerLinkActive="router-active">
          Home
        </a>
        <a [routerLink]="['/history']"
           routerLinkActive="router-active">
          History
        </a>
        <a [routerLink]="['/team']"
           routerLinkActive="router-active">
          Team
        </a>
        <a [routerLink]="['/services']"
           routerLinkActive="router-active">
          Services
        </a>
        <a [routerLink]="['/fees']"
           routerLinkActive="router-active">
          Fees
        </a>
        <a [routerLink]="['/login']" *ngIf="!(isAuth$ | async)"
           class="login"
           routerLinkActive="router-active">
          Log In
        </a>
        <ul *ngIf="isAuth$ && authService.user$ | async as user"
            (click)="toggleAccountMenu()"
            class="account-menu"
            qssDropdown
            routerLinkActive="router-active"
            [ngClass]="{'closed' : isAccountMenu , 'activated-route' : routeActivated}">
          <li class="account-menu__profile">
            <!--<div class="avatar"></div>-->
            <a>
              {{user.name}}
            </a>
          </li>
          <li class="account-menu__items">
            <ul>
              <li>
                <a [routerLink]="['/hub']">
                  Profile
                </a>
              </li>
              <li *ngIf="!authService.canEdit(user)">
                <a [routerLink]="['/hub', 'appointments']">
                  Appointments
                </a>
              </li>
              <!--<li *ngIf="!authService.canEdit(user)" class="qss-feature&#45;&#45;off">-->
                <!--<a [routerLink]="['/hub', 'medications']">-->
                  <!--Medications-->
                <!--</a>-->
              <!--</li>-->
              <li *ngIf="!authService.canEdit(user)">
                <a [routerLink]="['/hub', 'messages']">
                  Messages
                </a>
              </li>
              <li *ngIf="authService.canEdit(user)">
                <a [routerLink]="['/hub', 'admin-patients']">
                  Patients
                </a>
              </li>
              <li *ngIf="authService.canEdit(user)">
                <a [routerLink]="['/hub', 'admin-appointments']">
                  Appointments
                </a>
              </li>
              <li *ngIf="authService.canEdit(user)">
                <a [routerLink]="['/hub', 'admin-messages']">
                  Messages
                </a>
              </li>
              <li>
                <a (click)="onLogout()">
                  Log Out
                </a>
              </li>
            </ul>
          </li>
        </ul>


      </ul>
    <div class="mobile-menu-hide" (click)="menuOn()">
      <mat-icon svgIcon="menu-burger--s" class="icon burger-icon"></mat-icon>
    </div>
  </div>

  <div *ngIf="isNavMenu"
       @animateState
       class="mobile-menu">
    <!--<div [@listState]="routeItems?.length">-->
      <div @listState>

      <!--<a *ngFor="let routeItem of routeItems; let i = index"-->
         <!--[routerLink]="[routeItem.routerLink]"-->
         <!--(click)="menuOff()"-->
         <!--routerLinkActive="router-active">-->
        <!--{{routeItem.page}}-->
      <!--</a>-->


      <a [routerLink]="['/home']"
         (click)="menuOff()"
         routerLinkActive="router-active">
        Home
      </a>
      <a [routerLink]="['/history']"
         (click)="menuOff()"
         routerLinkActive="router-active">
        History
      </a>
      <a [routerLink]="['/team']"
         (click)="menuOff()"
         routerLinkActive="router-active">
        Team
      </a>
      <a [routerLink]="['/services']"
         (click)="menuOff()"
         routerLinkActive="router-active">
        Services
      </a>
      <a [routerLink]="['/fees']"
         (click)="menuOff()"
         routerLinkActive="router-active">
        Fees
      </a>
      <a [routerLink]="['/login']"
         *ngIf="!(isAuth$ | async)"
         (click)="menuOff()"
         routerLinkActive="router-active">
        Log In
      </a>
      <a [routerLink]="['/hub']"
         *ngIf="isAuth$ && authService.user$ | async as user"
         (click)="menuOff()"
         routerLinkActive="router-active">
        {{user.name}}
      </a>
    </div>
  </div>
</div>
